var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"itemRules"},[_c('b-card-body',{staticClass:"invoice-padding form-item-section"},[_c('div',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight})},_vm._l((_vm.invoiceData.items),function(item,index){return _c('b-row',{key:index,ref:"row",refInFor:true,staticClass:"pb-2"},[_c('b-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"d-none d-lg-flex"},[_c('b-row',{staticClass:"flex-grow-1 px-1"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" "+_vm._s(_vm.$t('Item'))+" ")]),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_vm._v(" "+_vm._s(_vm.$t('InvoiceInfo.Unit'))+" ")]),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_vm._v(" "+_vm._s(_vm.$t('InvoiceInfo.Price'))+" ")]),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_vm._v(" "+_vm._s(_vm.$t('InvoiceInfo.Qty'))+" ")]),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_vm._v(" "+_vm._s(_vm.$t('InvoiceInfo.Total'))+" ")])],1),_c('div',{staticClass:"form-item-action-col"})],1),_c('div',{staticClass:"d-flex border rounded"},[_c('b-row',{staticClass:"flex-grow-1 p-2"},[_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v(_vm._s(_vm.$t('Item')))]),_c('validation-provider',{attrs:{"name":_vm.$t('Item'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2 item-selector-title",attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":_vm.item_option,"label":_vm.$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en',"clearable":false,"placeholder":_vm.$t('Item')},on:{"input":function (val) { return _vm.updateItemForm(index, val); }},model:{value:(item.item),callback:function ($$v) {_vm.$set(item, "item", $$v)},expression:"item.item"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"3"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v(_vm._s(_vm.$t('Measurement Unit')))]),_c('validation-provider',{attrs:{"name":_vm.$t('Measurement Unit'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"mb-2 item-selector-title",attrs:{"dir":_vm.$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr',"options":item.measurement_unit_option,"label":_vm.$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en',"clearable":false,"placeholder":_vm.$t('Measurement Unit')},on:{"input":function (val) { return _vm.updateMeasurementForm(index, val); }},model:{value:(item.measurement_unit),callback:function ($$v) {_vm.$set(item, "measurement_unit", $$v)},expression:"item.measurement_unit"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v(_vm._s(_vm.$t('InvoiceInfo.Price')))]),_c('validation-provider',{attrs:{"name":_vm.$t('InvoiceInfo.Price'),"rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticClass:"mb-2",attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":_vm.$t('InvoiceInfo.Price')},on:{"keyup":function($event){return _vm.sumForm()}},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", _vm._n($$v))},expression:"item.price"}})]}}],null,true)}),_c('b-form-input',{staticClass:"mb-2 d-none",attrs:{"type":"number"},model:{value:(item.item_id),callback:function ($$v) {_vm.$set(item, "item_id", $$v)},expression:"item.item_id"}}),_c('b-form-input',{staticClass:"mb-2 d-none",attrs:{"type":"number"},model:{value:(item.measurement_unit_id),callback:function ($$v) {_vm.$set(item, "measurement_unit_id", $$v)},expression:"item.measurement_unit_id"}})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v(_vm._s(_vm.$t('InvoiceInfo.Qty')))]),_c('validation-provider',{attrs:{"name":_vm.$t('InvoiceInfo.Qty'),"rules":"required|regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{class:_vm.classQty,attrs:{"state":errors.length > 0 ? false:null,"type":"number","placeholder":_vm.$t('InvoiceInfo.Qty')},on:{"keyup":function (val) { return _vm.validationQuantity(index); }},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}}),(_vm.showOutOfwharehousQuantity)?_c('b-form-invalid-feedback',[_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.$t('InvoiceInfo.Quantity not available in Warehouse')))])]):_vm._e()]}}],null,true)})],1),_c('b-col',{attrs:{"cols":"12","lg":"2"}},[_c('label',{staticClass:"d-inline d-lg-none"},[_vm._v(_vm._s(_vm.$t('InvoiceInfo.Total')))]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(item.price * item.quantity)+"£ ")])])],1),_c('div',{staticClass:"d-flex flex-column justify-content-between border-left py-50 px-25"},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"size":"16","icon":"XIcon"},on:{"click":function($event){return _vm.removeItem(index)}}})],1)],1)])],1)}),1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"size":"sm","variant":"primary"},on:{"click":_vm.addNewItemInItemForm}},[_vm._v(" "+_vm._s(((_vm.$t('General.Add')) + " " + (_vm.$t('Item'))))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }