<template>
  <div>
    <!-- Invoice Description: Total -->
    <b-card-body class="invoice-padding pb-0">
      <b-row>
        <!-- Col: Sales Persion -->
        <b-col
          cols="12"
          md="6"
          class="mt-md-0 mt-3 align-items-center"
          order="2"
          order-md="1"
        >
          <b-form-group
            :label="$t('General.Attachment')"
            label-for="attachment"
            class="mt-1"
          >
            <b-form-file
              v-model="invoiceData.file"
              :placeholder="$t('General.Choose a file or drop it here')"
              :drop-placeholder="$t('General.Drop file here')"
              multiple
            />
          </b-form-group>
        </b-col>
        <!-- Col: Total -->
        <b-col
          cols="12"
          md="6"
          class="mt-md-6 d-flex justify-content-end"
          order="1"
          order-md="2"
        >
          <div class="invoice-total-wrapper">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('InvoiceInfo.Subtotal') }}:
              </p>
              <p class="invoice-total-amount">
                {{ invoiceData.master.total_price }}£
              </p>
            </div>
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('InvoiceInfo.Discount') }}:
              </p>
              <p class="invoice-total-amount">
                0£
              </p>
            </div>
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('InvoiceInfo.Tax') }}:
              </p>
              <p class="invoice-total-amount">
                0%
              </p>
            </div>
            <hr class="my-50">
            <div class="invoice-total-item">
              <p class="invoice-total-title">
                {{ $t('InvoiceInfo.Total') }}:
              </p>
              <p class="invoice-total-amount">
                {{ invoiceData.master.total_price }}£
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-card-body>
    <!-- Spacer -->
    <hr class="invoice-spacing">
    <!-- Note -->
    <b-card-body class="invoice-padding pt-0">
      <span class="font-weight-bold">{{ $t('General.Notes') }}: </span>
      <b-form-textarea v-model="invoiceData.master.description" />
    </b-card-body>
  </div>
</template>

<script>
import {
  BRow, BCol, BCardBody, BFormTextarea, BFormFile, BFormGroup,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCardBody,
    BFormTextarea,
    BFormFile,
    BFormGroup,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  async created() {
    this.invoiceData.master.description = this.invoiceData.master.description ? this.invoiceData.master.description : ''
  },
}
</script>
