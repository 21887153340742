import saleInvoice from '@/service/sale/sale-invoice'
import saleOffer from '@/service/sale/sale-offer'
import saleOrder from '@/service/sale/sale-order'
import saleRequest from '@/service/sale/sale-request'
import saleRetrun from '@/service/sale/sale-return'
import purchaseInvoice from '@/service/purchase/purchase-invoice'
import purchaseOffer from '@/service/purchase/purchase-offer'
import purchaseOrder from '@/service/purchase/purchase-order'
import purchaseRequset from '@/service/purchase/purchase-request'
import purchaseReturn from '@/service/purchase/purchase-return'
import deposit from '@/service/warehouse/deposit'
import destroy from '@/service/warehouse/destroy'
import ecommerce from '@/service/warehouse/ecommerce'
import withdraw from '@/service/warehouse/withdraw'
import transfer from '@/service/warehouse/transfer'

const getDocumentBased = async (documentTypeId, documentNo) => {
  let resultData = []
  switch (documentTypeId) {
    case 1:
      resultData = await deposit.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'supply_voucher_details' }
      break
    case 2:
      resultData = await withdraw.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'exchange_voucher_details' }
      break
    case 3:
      resultData = await saleRequest.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'sale_request_details' }
      break
    case 4:
      resultData = await saleOffer.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'sale_quotation_details' }
      break
    case 5:
      resultData = await saleOrder.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'sale_order_details' }
      break
    case 6:
      resultData = await saleInvoice.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'sale_invoice_details' }
      break
    case 7:
      resultData = await saleRetrun.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'sale_return_details' }
      break
    case 8:
      resultData = await purchaseRequset.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'purchase_request_details' }
      break
    case 9:
      resultData = await purchaseOffer.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'purchase_quotation_details' }
      break
    case 10:
      resultData = await purchaseOrder.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'purchase_order_details' }
      break
    case 11:
      resultData = await purchaseInvoice.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'purchase_invoice_details' }
      break
    case 12:
      resultData = await purchaseReturn.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'purchase_return_details' }
      break
    case 13:
      resultData = await transfer.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'transfer_warehouse_details' }
      break
    case 14:
      resultData = await destroy.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'depreciation_details' }
      break
    case 15:
      resultData = await ecommerce.searchByDocument(documentTypeId, documentNo).then(response => response.data).catch(error => error)
      resultData = { ...resultData, detial: 'supply_voucher_details' }
      break
    default:
      break
  }
  return resultData
}

export default {
  getDocumentBased,
}
