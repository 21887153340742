<template>
  <div>
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">

      <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">

        <!-- Header: Left Content -->
        <div>
          <div class="logo-wrapper">
            <logo />
            <h3 class="text-primary invoice-logo">
              {{ invoiceData.documentName }}
            </h3>
          </div>
          <h4>
            {{ $store.state.appConfig.layout.isRTL ? one_organization.name_ar : one_organization.name_en }}
          </h4>
          <b-card-text class="mb-25">
            {{ one_organization.address }}
          </b-card-text>
          <b-card-text class="mb-25">
            {{ one_organization.email }}, {{ one_organization.website }}
          </b-card-text>
          <b-card-text class="mb-0">
            {{ one_organization.phone_one }}, {{ one_organization.phone_tow }}
          </b-card-text>
        </div>

        <!-- Header: Right Content -->
        <div class="invoice-number-date mt-md-0 mt-2">
          <div class="d-flex align-items-center justify-content-md-end mb-1">
            <h4 class="title">
              {{ $t('General.Number') }}
            </h4>
            <b-input-group class="input-group-merge invoice-edit-input-group disabled">
              <b-input-group-prepend is-text>
                <feather-icon icon="HashIcon" />
              </b-input-group-prepend>
              <b-form-input
                id="invoice-data-id"
                v-model="invoiceData.master.document_no"
                disabled
              />
            </b-input-group>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="title">
              {{ $t('InvoiceInfo.Date Issued') }}:
            </span>
            <validation-provider
              #default="{ errors }"
              :name="$t('InvoiceInfo.Date Issued')"
              rules="required"
            >
              <flat-pickr
                v-model="invoiceData.master.date"
                class="form-control invoice-edit-input"
                :placeholder="$t('InvoiceInfo.Date Issued')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
          <div class="d-flex align-items-center mb-1">
            <span class="title">
              {{ $t('InvoiceInfo.Due Date') }}:
            </span>
            <validation-provider
              #default="{ errors }"
              :name="$t('InvoiceInfo.Due Date')"
              rules="required"
            >
              <flat-pickr
                v-model="invoiceData.master.due_date"
                class="form-control invoice-edit-input"
                :placeholder="$t('InvoiceInfo.Due Date')"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </div>
        </div>
      </div>
    </b-card-body>

    <!-- Spacer -->
    <hr class="invoice-spacing">

    <!-- Invoice Client & Payment Details -->
    <b-card-body
      class="invoice-padding pt-0"
    >
      <b-row class="invoice-spacing">

        <!-- Col: Invoice To -->
        <b-col
          cols="12"
          xl="6"
          class="mb-lg-1"
        >
          <h6 class="mb-2">
            {{ $t('Document') }}:
          </h6>
          <validation-provider
            #default="{ errors }"
            :name="$t('Document')"
            rules="required"
          >
            <b-form-group
              label-for="document"
              :state="errors.length > 0 ? false:null"
            >
              <!-- Select Client -->
              <v-select
                v-model="invoiceData.document"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="child_documnet"
                :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                input-id="invoice-data-client"
                :clearable="false"
                :placeholder="$t('Document')"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false:null"
              >
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <!-- Col: Document -->
        <b-col
          cols="12"
          xl="6"
          class="mb-lg-1"
        >
          <h6 class="mb-2">
            {{ $t('Warehouse') }}
          </h6>
          <!-- Select Document -->
          <validation-provider
            #default="{ errors }"
            :name="$t('Warehouse')"
            rules="required"
          >
            <v-select
              v-model="invoiceData.master.warehouse_id"
              :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
              :options="warehouse_option"
              :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
              input-id="warehouse-method"
              class="payment-selector"
              :clearable="false"
              :placeholder="$t('Warehouse')"
            />
            <b-form-invalid-feedback
              :state="errors.length > 0 ? false:null"
            >
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-invalid-feedback>
          </validation-provider>
        </b-col>
        <!-- Selected Doucment -->

        <!-- Col: Invoice To -->
        <b-col
          cols="12"
          xl="6"
          class="mb-lg-1"
        >
          <h6 class="mb-2">
            {{ invoiceData.to }}:
          </h6>
          <validation-provider
            #default="{ errors }"
            :name="invoiceData.clientOrVenderName"
            rules="required"
          >
            <b-form-group
              label-for="client"
              :state="errors.length > 0 ? false:null"
            >
              <!-- Select Client -->
              <v-select
                v-model="invoiceData.client"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="marge_vendor_bank"
                :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                input-id="invoice-data-client"
                :clearable="false"
                :placeholder="invoiceData.clientOrVenderName"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false:null"
              >
                <small class="text-danger">{{ errors[0] }}</small>
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Selected Client -->
          <div
            v-if="invoiceData.client"
            class="mt-3"
          >
            <h6 class="mb-25">
              {{ $store.state.appConfig.layout.isRTL ? invoiceData.client.name_ar : invoiceData.client.name_en }}
            </h6>
            <b-card-text class="mb-25">
              {{ $store.state.appConfig.layout.isRTL ? invoiceData.client.company.name_ar : invoiceData.client.company.name_en }}
            </b-card-text>
            <b-card-text class="mb-25">
              {{ invoiceData.client.address }}, {{ invoiceData.client.area_id }}
            </b-card-text>
            <b-card-text class="mb-25">
              {{ invoiceData.client.phone }}, {{ invoiceData.client.tel }}
            </b-card-text>
            <b-card-text class="mb-0">
              {{ invoiceData.client.email }}
            </b-card-text>
          </div>
        </b-col>
        <!-- Col: Document -->
        <b-col
          cols="12"
          xl="6"
          class="mb-lg-1"
        >
          <h6 class="mb-2">
            {{ $t('InvoiceInfo.Documented based on') }}
          </h6>

          <div class="row">
            <b-col
              cols="8"
              xl="8"
              class="mb-lg-1"
            >
              <!-- Select Document -->
              <v-select
                v-model="invoiceData.master.based_document_type_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="document_option"
                :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                input-id="invoice-data-client"
                :clearable="false"
                :placeholder="$t('InvoiceInfo.Documented based on')"
              />
            </b-col>
            <b-col
              cols="4"
              xl="4"
              class="mb-lg-1"
            >
              <b-input-group class="input-group-merge invoice-edit-input-group">
                <b-form-input
                  v-model="invoiceData.master.based_document_no"
                  :placeholder="$t('General.No')"
                  @keyup="getDocumentBasedOn()"
                />
              </b-input-group>
            </b-col>
          </div>
          <div v-if="invoiceData.client">
            <h6 class="mt-3">
              {{ `${$t('Payment')} ${$t('Details')}: ` }}
            </h6>
            <table>
              <tbody>
                <tr>
                  <td class="pr-1">
                    {{ $t('InvoiceInfo.Total Due') }}:
                  </td>
                  <td><span class="font-weight-bold">{{ invoiceData.master.total_price }}£</span></td>
                </tr>
                <tr v-if="invoiceData.isEdit">
                  <td class="pr-1">
                    {{ $t('General.Balance') }}:
                  </td>
                  <td><span class="font-weight-bold">{{ invoiceData.balance }}£</span></td>
                </tr>
                <tr>
                  <td class="pr-1">
                    {{ `${$t('Bank')} ${$t('General.Name')}: ` }}
                  </td>
                  <td>{{ $store.state.appConfig.layout.isRTL ? invoiceData.client.bank_branch.bank.name_ar : invoiceData.client.bank_branch.bank.name_en }}</td>
                </tr>
                <tr>
                  <td class="pr-1">
                    {{ $t('General.IBAN') }}:
                  </td>
                  <td>{{ invoiceData.client.IBAN_account_number }}</td>
                </tr>
                <tr>
                  <td class="pr-1">
                    {{ `${$t('Bank')} ${$t('General.Card')}: ` }}
                  </td>
                  <td>{{ invoiceData.client.bank_card }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Selected Doucment -->
        </b-col>
        <!-- Col: Payment Details -->
      </b-row>
    </b-card-body>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import Logo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BCardBody, BCardText, BFormInput, BInputGroup, BInputGroupPrepend, BFormGroup, BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import branch from '@/service/administration/branch'
import document from '@/service/administration/document'
import documentInvoice from '@/service/sale/document-invoice'
import supplier from '@/service/crm/supplier'
import financialYear from '@/service/accounting/financial-year'
import bankBranch from '@/service/administration/bank-branch'
import status from '@/service/administration/status'
import paymentOperation from '@/service/warehouse/payment-operation'
import warehouse from '@/service/warehouse/warehouse'

export default {
  components: {
    ValidationProvider,
    BRow,
    BCol,
    BCardBody,
    BCardText,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormInvalidFeedback,
    flatPickr,
    vSelect,
    Logo,
  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      id: 0,
      required,
      one_organization: '10',
      document_id: '',
      document_option: [],
      vendor_set_id: '',
      vendor_set_option: [],
      bank_branch_option: [],
      marge_vendor_bank: [],
      warehouse_option: [],
      child_documnet: [],
    }
  },
  async created() {
    await this.getApiSelect()
    const today = new Date()
    const dateNow = `${today.getFullYear()}-${(today.getMonth() + 1)}-${today.getDate()}`
    this.invoiceData.master.date = dateNow
    this.invoiceData.master.due_date = dateNow
  },
  methods: {
    async getApiSelect() {
      this.invoiceData.showOverLay = true
      await document.getAxios().then(response => {
        this.document_option = response.data
        this.document_option.forEach(doc => {
          if (doc.parent === 3 || doc.id === this.invoiceData.document_id) {
            this.child_documnet.push(doc)
          }
        })
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await supplier.searchAxios(this.invoiceData.clientOrVenderId).then(response => {
        console.log(response.data)
        this.vendor_set_option = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await branch.oneAxios(this.invoiceData.master.organization_branch_id).then(response => {
        this.one_organization = response.data.organization
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await bankBranch.getAxios().then(response => {
        this.bank_branch_option = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await financialYear.getAxios().then(response => {
        this.invoiceData.master.financial_year_id = response.data.find(x => x.active === 1)
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await document.oneAxios(this.invoiceData.document_id).then(response => {
        this.invoiceData.document = response.data
        this.invoiceData.payment = response.data.is_payment
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await document.getDocumentNoAxios(this.invoiceData.document).then(response => {
        if (!this.invoiceData.isEdit) {
          this.invoiceData.master.document_no = response.data
        } else {
          this.getBalance()
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await warehouse.getAxios().then(response => {
        this.warehouse_option = response.data
        if (response.data) {
          // eslint-disable-next-line prefer-destructuring
          this.invoiceData.master.warehouse_id = this.warehouse_option[0]
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => { this.invoiceData.showOverLay = false })
      setTimeout(() => {
        this.marge_vendor_bank = this.vendor_set_option.map(vendor => {
          // eslint-disable-next-line camelcase
          const bank_branch = this.bank_branch_option.find(x => x.id === vendor.bank_branch_id)
          return { ...vendor, bank_branch }
        })
      }, 1000)
    },
    async getBalance() {
      await paymentOperation.searchByDocument(this.invoiceData.document_id, this.invoiceData.master.id).then(response => {
        if (response.data.length === 0) {
          this.invoiceData.balance = this.invoiceData.master.total_price
        } else {
          this.payment_operation_option = response.data
          this.invoiceData.balance = this.invoiceData.master.total_price - (this.payment_operation_option.reduce((a, b) => +a + +b.amount, 0))
        }
      }).catch(error => {
        this.apiErrors = error
        this.showError = true
      })
    },
    async getDocumentBasedOn() {
      if (this.invoiceData.master.based_document_type_id && this.invoiceData.master.based_document_no) {
        this.invoiceData.showOverLay = true
        let isresponse = false
        await documentInvoice.getDocumentBased(this.invoiceData.master.based_document_type_id.id, this.invoiceData.master.based_document_no).then(response => {
          if (response) {
            isresponse = true
            const oldBasedDocumentNo = this.invoiceData.master.based_document_no
            const oldBasedDocumentTypeId = this.invoiceData.master.based_document_type_id
            this.invoiceData.master = response
            this.invoiceData.master.warehouse_id = response.warehouse
            this.invoiceData.master.based_document_id = response.id
            this.invoiceData.items = response[response.detial]
            this.invoiceData.client = this.marge_vendor_bank.find(x => x.id === response.vendor_set_id)
            this.invoiceData.master.based_document_no = oldBasedDocumentNo
            this.invoiceData.master.based_document_type_id = oldBasedDocumentTypeId
          } else isresponse = false
          this.invoiceData.showError = false
        }).catch(error => {
          this.invoiceData.apiErrors = error
          this.invoiceData.showError = true
        })
        if (isresponse) {
          await status.oneAxios(this.invoiceData.master.status_id).then(response => {
            this.invoiceData.master.status_id = response.data
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          })
          await document.getDocumentNoAxios(this.invoiceData.document).then(response => {
            this.invoiceData.master.document_no = response.data
            this.loadItemUpdate()
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          })
          await financialYear.getAxios().then(response => {
            this.invoiceData.master.financial_year_id = response.data.find(x => x.id === this.invoiceData.master.financial_year_id)
          }).catch(error => {
            this.invoiceData.apiErrors = error
            this.invoiceData.showError = true
          }).finally(() => {
            window.addEventListener('resize', this.initTrHeight)
            this.invoiceData.showOverLay = false
          })
        } else this.invoiceData.showOverLay = false
      }
    },
    loadItemUpdate() {
      let measurement = ''
      this.invoiceData.items.forEach((itemVal, index) => {
        this.invoiceData.items[index].measurement_unit_option = []
        measurement = ''
        itemVal.item.item_details.forEach(itemDetail => {
          measurement = {
            ...itemDetail.measurement_unit,
            sale_price: itemDetail.sale_price,
            purchase_price: itemDetail.purchase_price,
            pieces: itemDetail.pieces,
          }
          this.invoiceData.items[index].measurement_unit_option.push(measurement)
        })
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.invoice-add-wrapper {
  .add-new-client-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {

  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}
</style>
