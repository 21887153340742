<template>
  <!-- Right Col: Card -->
  <b-col
    cols="12"
    md="4"
    xl="3"
    class="invoice-actions mt-md-0 mt-2"
  >

    <!-- Action Buttons -->
    <b-card>

      <!-- Button: Send Invoice -->
      <b-button
        v-if="invoiceData.send && $can('read', invoiceData.permissionEdit)"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        v-b-toggle.sidebar-send-invoice
        variant="primary"
        class="mb-75"
        block
      >
        {{ `${$t('General.Send')} ${$t('General.Email')}` }}
      </b-button>

      <!-- Button: DOwnload -->
      <b-button
        v-if="invoiceData.download && $can('read', invoiceData.permissionEdit)"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
      >
        {{ $t('General.Download') }}
      </b-button>

      <!-- Button: Print -->
      <b-button
        v-if="invoiceData.print && $can('read', invoiceData.permissionEdit)"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        @click="window.print()"
      >
        {{ $t('General.Print') }}
      </b-button>

      <!-- Button: Edit -->
      <b-button
        v-if="invoiceData.edit && $can('update', invoiceData.permissionEdit)"
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        class="mb-75"
        block
        :to="{ name: invoiceData.routEdit, params: { id: invoiceData.master.id } }"
      >
        {{ $t('General.Edit') }}
      </b-button>

      <!-- Button: Add Payment --><!-- v-if="invoiceData.payment && $can('add', 'PaymentInvoice')" -->
      <b-button
        v-if="invoiceData.payment && $can('add', 'PaymentInvoice')"
        v-b-toggle.sidebar-invoice-add-payment
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="success"
        class="mb-75"
        block
      >
        {{ $t('Payment') }}
      </b-button>

      <!-- Button: DOwnload -->
      <b-button
        v-if="invoiceData.preview && $can('read', invoiceData.permissionEdit)"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        variant="outline-primary"
        class="mb-75"
        block
        :to="{ name: invoiceData.routePerview, params: { id: invoiceData.master.id } }"
      >
        {{ $t('General.Preview') }}
      </b-button>
      <!-- Button: Reset -->
      <b-button
        v-if="invoiceData.reset"
        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
        type="reset"
        variant="outline-primary"
        class="mb-75"
        block
        @click="$emit('clear-form')"
      >
        {{ $t('General.Reset') }}
      </b-button>
      <!-- Button: Print -->
      <b-button
        v-if="invoiceData.save"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click="$emit('submit-form')"
      >
        {{ invoiceData.saveUpdateText }}
      </b-button>
    </b-card>

    <!-- Payment Method -->
    <div class="mt-2">
      <b-form-group
        :label="$t('Status')"
        label-for="status"
      >
        <v-select
          v-model="invoiceData.master.status_id"
          :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
          :options="status_option"
          input-id="status-method"
          :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
          class="payment-selector"
          :clearable="false"
          :placeholder="$t('Status')"
        />
      </b-form-group>

      <!-- ? Below values are not adding invoiceData to keep invoiceData more generic and less confusing  -->

      <!-- Payment Terms -->
      <div class="d-flex justify-content-between align-items-center">
        <label for="patymentTerms">{{ $t('General.Suspended') }}</label>
        <b-form-checkbox
          id="patymentTerms"
          v-model="invoiceData.master.suspended"
          :checked="true"
          switch
        />
      </div>
    </div>
    <b-card
      v-if="payment_operation_option & !payment_operation_option.length"
      class="mt-3"
    >
      <b-table-lite
        responsive
        :items="payment_operation_option"
        :fields="['payment', 'amount']"
      >
        <template #cell(payment)="data">
          <b-card-text class="font-weight-bold mb-25">
            {{ $store.state.appConfig.layout.isRTL ? data.item.payment_operation.name_ar : data.item.payment_operation.name_en }}
          </b-card-text>
        </template>
      </b-table-lite>
    </b-card>
  </b-col>
</template>

<script>
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import {
  BCol, BCard, BButton, BFormGroup, BFormCheckbox, VBToggle, BTableLite, BCardText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import status from '@/service/administration/status'
import paymentOperation from '@/service/warehouse/payment-operation'
import payment from '@/service/administration/payment'

export default {
  components: {
    BCol,
    BCard,
    BButton,
    BFormGroup,
    BFormCheckbox,
    BTableLite,
    BCardText,
    vSelect,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,

  },
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      status_option: [],
      payment_operation_option: [],
      payment_option: [],
    }
  },
  async created() {
    this.getApiSelect()
  },
  methods: {
    async getApiSelect() {
      this.invoiceData.showOverLay = true
      await status.getAxios().then(response => {
        this.status_option = response.data
        if (!this.invoiceData.isEdit && response.data) {
          // eslint-disable-next-line prefer-destructuring
          this.invoiceData.master.status_id = this.status_option[0]
        }
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      })
      await payment.getAxios().then(response => {
        this.payment_option = response.data
        this.getBalance()
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => { this.invoiceData.showOverLay = false })
    },
    async getBalance() {
      if (this.invoiceData.master.id !== 0 && this.invoiceData.master.id) {
        await paymentOperation.searchByDocument(this.invoiceData.document_id, this.invoiceData.master.id).then(response => {
          this.payment_operation_option = response.data
        }).catch(error => {
          this.invoiceData.apiErrors = error
          this.invoiceData.showError = true
        })
        setTimeout(() => {
          this.payment_operation_option = this.payment_operation_option.map(vendor => {
            // eslint-disable-next-line camelcase
            const payment_operation = this.payment_option.find(x => x.id === vendor.payment_id)
            return { ...vendor, payment_operation }
          })
        }, 1000)
      }
    },
  },
}
</script>
