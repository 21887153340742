import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/purchaseOrders')
const postAxios = data => axiosWarehouse.post('/purchaseOrders', data)
const oneAxios = id => axiosWarehouse.get(`/purchaseOrders/${id}`)
const searchAxios = id => axiosWarehouse.get(`/purchaseOrders/search/${id}`)
const lastIdAxios = id => axiosWarehouse.get(`/purchaseOrders/last/${id}`)
const putAxios = (id, data) => axiosWarehouse.post(`/purchaseOrders/${id}?_method=PUT`, data)
const deleteAxios = id => axiosWarehouse.delete(`/purchaseOrders/${id}`)
const searchByDocument = (documentTypeId, documentNo) => axiosWarehouse.get(`/purchaseOrders/searchByDocument/${documentTypeId}/${documentNo}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  searchAxios,
  lastIdAxios,
  searchByDocument,
}
