import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/purchaseRequests')
const postAxios = data => axiosWarehouse.post('/purchaseRequests', data)
const oneAxios = id => axiosWarehouse.get(`/purchaseRequests/${id}`)
const searchAxios = id => axiosWarehouse.get(`/purchaseRequests/search/${id}`)
const lastIdAxios = id => axiosWarehouse.get(`/purchaseRequests/last/${id}`)
const putAxios = (id, data) => axiosWarehouse.post(`/purchaseRequests/${id}?_method=PUT`, data)
const deleteAxios = id => axiosWarehouse.delete(`/purchaseRequests/${id}`)
const searchByDocument = (documentTypeId, documentNo) => axiosWarehouse.get(`/purchaseRequests/searchByDocument/${documentTypeId}/${documentNo}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  searchAxios,
  lastIdAxios,
  searchByDocument,
}
