import axiosWarehouse from '@/libs/axios-warehouse'

const getAxios = () => axiosWarehouse.get('/supplyVouchers')
const postAxios = data => axiosWarehouse.post('/supplyVouchers', data)
const oneAxios = id => axiosWarehouse.get(`/supplyVouchers/${id}`)
const searchAxios = id => axiosWarehouse.get(`/supplyVouchers/search/${id}`)
const lastIdAxios = id => axiosWarehouse.get(`/supplyVouchers/last/${id}`)
const putAxios = (id, data) => axiosWarehouse.post(`/supplyVouchers/${id}?_method=PUT`, data)
const deleteAxios = id => axiosWarehouse.delete(`/supplyVouchers/${id}`)
const searchByDocument = (documentTypeId, documentNo) => axiosWarehouse.get(`/supplyVouchers/searchByDocument/${documentTypeId}/${documentNo}`)

export default {
  getAxios,
  postAxios,
  oneAxios,
  putAxios,
  deleteAxios,
  searchAxios,
  lastIdAxios,
  searchByDocument,
}
