<template>
  <!-- Items Section -->
  <validation-observer ref="itemRules">
    <b-card-body class="invoice-padding form-item-section">
      <div
        ref="form"
        class="repeater-form"
        :style="{height: trHeight}"
      >
        <b-row
          v-for="(item, index) in invoiceData.items"
          :key="index"
          ref="row"
          class="pb-2"
        >

          <!-- Item Form -->
          <!-- ? This will be in loop => So consider below markup for single item -->
          <b-col cols="12">

            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div class="d-none d-lg-flex">
              <b-row class="flex-grow-1 px-1">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('Item') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  {{ $t('InvoiceInfo.Unit') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  {{ $t('InvoiceInfo.Price') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  {{ $t('InvoiceInfo.Qty') }}
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  {{ $t('InvoiceInfo.Total') }}
                </b-col>
              </b-row>
              <div class="form-item-action-col" />
            </div>

            <!-- Form Input Fields OR content inside bordered area  -->
            <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
            <div class="d-flex border rounded">
              <b-row class="flex-grow-1 p-2">
                <!-- Single Item Form Headers -->
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('Item') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Item')"
                    rules="required"
                  >
                    <v-select
                      v-model="item.item"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="item_option"
                      :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                      :clearable="false"
                      class="mb-2 item-selector-title"
                      :placeholder="$t('Item')"
                      @input="val => updateItemForm(index, val)"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false:null"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  lg="3"
                >
                  <label class="d-inline d-lg-none">{{ $t('Measurement Unit') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('Measurement Unit')"
                    rules="required"
                  >
                    <v-select
                      v-model="item.measurement_unit"
                      :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                      :options="item.measurement_unit_option"
                      :label="$store.state.appConfig.layout.isRTL ? 'name_ar' : 'name_en'"
                      :clearable="false"
                      class="mb-2 item-selector-title"
                      :placeholder="$t('Measurement Unit')"
                      @input="val => updateMeasurementForm(index, val)"
                    />
                    <b-form-invalid-feedback
                      :state="errors.length > 0 ? false:null"
                    >
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-invalid-feedback>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  <label class="d-inline d-lg-none">{{ $t('InvoiceInfo.Price') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('InvoiceInfo.Price')"
                    rules="required|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      v-model.number="item.price"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      class="mb-2"
                      :placeholder="$t('InvoiceInfo.Price')"
                      @keyup="sumForm()"
                    />
                  </validation-provider>
                  <b-form-input
                    v-model="item.item_id"
                    type="number"
                    class="mb-2 d-none"
                  />
                  <b-form-input
                    v-model="item.measurement_unit_id"
                    type="number"
                    class="mb-2 d-none"
                  />
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  <label class="d-inline d-lg-none">{{ $t('InvoiceInfo.Qty') }}</label>
                  <validation-provider
                    #default="{ errors }"
                    :name="$t('InvoiceInfo.Qty')"
                    rules="required|regex:^([0-9]+)$"
                  >
                    <b-form-input
                      v-model.number="item.quantity"
                      :state="errors.length > 0 ? false:null"
                      type="number"
                      :class="classQty"
                      :placeholder="$t('InvoiceInfo.Qty')"
                      @keyup="val => validationQuantity(index)"
                    />
                    <b-form-invalid-feedback
                      v-if="showOutOfwharehousQuantity"
                    >
                      <small class="text-danger">{{ $t('InvoiceInfo.Quantity not available in Warehouse') }}</small>
                    </b-form-invalid-feedback>
                    <!-- </b-form-input> -->
                    <!-- @keyup="sumForm()" -->
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  lg="2"
                >
                  <label class="d-inline d-lg-none">{{ $t('InvoiceInfo.Total') }}</label>
                  <p class="mb-1">
                    {{ item.price * item.quantity }}£
                  </p>
                </b-col>
              </b-row>
              <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                <feather-icon
                  size="16"
                  icon="XIcon"
                  class="cursor-pointer"
                  @click="removeItem(index)"
                />
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        size="sm"
        variant="primary"
        @click="addNewItemInItemForm"
      >
        {{ `${$t('General.Add')} ${$t('Item')}` }}
      </b-button>
    </b-card-body>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BCardBody, BButton, BFormInput, BFormInvalidFeedback,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { required, integer } from '@validations'
// import measurementUnit from '@/service/warehouse/measurement-unit'
import item from '@/service/warehouse/item'

export default {
  components: {
    ValidationObserver,
    ValidationProvider,
    BRow,
    BCol,
    BCardBody,
    BButton,
    BFormInput,
    BFormInvalidFeedback,
    vSelect,
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    invoiceData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      integer,
      measurement_unit_option: [],
      item_option: [],
      count: 0,
      classQty: 'mb-2 form-control',
      showOutOfwharehousQuantity: false,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    this.showOverLay = true
    // this.invoiceData.items = [JSON.parse(JSON.stringify(this.itemFormBlankItem))]
    window.addEventListener('resize', this.initTrHeight)
    this.getApiSelect()
    if (this.invoiceData.isEdit) {
      this.loadItemUpdate()
    }
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    addNewItemInItemForm() {
      // this.$emit('handle-form-data', this.invoiceData.items)
      this.$refs.itemRules.validate().then(success => {
        if (success) {
          this.$refs.form.style.overflow = 'hidden'
          this.invoiceData.items.push(JSON.parse(JSON.stringify(this.itemFormBlankItem)))

          this.$nextTick(() => {
            this.trAddHeight(this.$refs.row[0].offsetHeight)
            setTimeout(() => {
              this.$refs.form.style.overflow = null
            }, 350)
          })
          this.count += 1
        }
      })
    },
    removeItem(index) {
      this.invoiceData.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async getApiSelect() {
      await item.getAxios().then(response => {
        this.item_option = response.data
      }).catch(error => {
        this.invoiceData.apiErrors = error
        this.invoiceData.showError = true
      }).finally(() => { this.invoiceData.showOverLay = false })
      // await measurementUnit.getAxios().then(response => {
      //   this.measurement_unit_option = response.data
      // }).catch(error => {
      //   this.invoiceData.apiErrors = error
      //   this.invoiceData.showError = true
      // })
    },
    sumForm() {
      this.invoiceData.master.total_price = this.invoiceData.items.reduce((a, b) => +a + +b.quantity * b.price, 0)
    },
    async updateItemForm(index, val) {
      this.invoiceData.items[index].measurement_unit_option = []
      let measurement = ''
      this.invoiceData.items[index].item_id = val.id
      await val.item_details.forEach(itemVal => {
        measurement = {
          ...itemVal.measurement_unit,
          sale_price: itemVal.sale_price,
          purchase_price: itemVal.purchase_price,
          pieces: itemVal.pieces,
        }
        this.invoiceData.items[index].measurement_unit_option.push(measurement)
      })
      if (this.invoiceData.items[index].measurement_unit_option) {
        // eslint-disable-next-line prefer-destructuring
        this.invoiceData.items[index].measurement_unit = this.invoiceData.items[index].measurement_unit_option[0]
        this.invoiceData.items[index].measurement_unit_id = this.invoiceData.items[index].measurement_unit_option[0].id
        if (this.getDocumentPrice()) {
          this.invoiceData.items[index].price = val.item_details[0].sale_price
        } else {
          this.invoiceData.items[index].price = val.item_details[0].purchase_price
        }
        this.sumForm()
      }
    },
    loadItemUpdate() {
      let measurement = ''
      this.invoiceData.items.forEach((itemVal, index) => {
        this.invoiceData.items[index].measurement_unit_option = []
        measurement = ''
        itemVal.item.item_details.forEach(itemDetail => {
          measurement = {
            ...itemDetail.measurement_unit,
            sale_price: itemDetail.sale_price,
            purchase_price: itemDetail.purchase_price,
            pieces: itemDetail.pieces,
          }
          this.invoiceData.items[index].measurement_unit_option.push(measurement)
        })
      })
      // if (this.measurement_unit_option) {
      //   // eslint-disable-next-line prefer-destructuring
      //   this.invoiceData.items[index].measurement_unit = this.measurement_unit_option[0]
      //   this.invoiceData.items[index].measurement_unit_id = this.measurement_unit_option[0].id
      //   if (this.getDocumentPrice()) {
      //     this.invoiceData.items[index].price = val.item_details[0].sale_price
      //   } else {
      //     this.invoiceData.items[index].price = val.item_details[0].purchase_price
      //   }
      //   this.sumForm()
      // }
    },
    updateMeasurementForm(index, val) {
      this.invoiceData.items[index].measurement_unit_id = val.id
      this.validationQuantity(index)
      if (this.getDocumentPrice()) {
        this.invoiceData.items[index].price = val.sale_price
      } else {
        this.invoiceData.items[index].price = val.purchase_price
      }
      this.sumForm()
    },
    validationQuantity(index) {
      if (this.getDocumentPrice()) {
        if (this.invoiceData.items[index].item != null) {
          const currentQuantity = this.invoiceData.items[index].item.current_quantity / this.invoiceData.items[index].measurement_unit.pieces
          if (currentQuantity >= this.invoiceData.items[index].quantity) {
            this.classQty = 'mb-2 form-control'
            this.showOutOfwharehousQuantity = false
          } else {
            this.invoiceData.items[index].quantity = 1
            this.classQty = 'mb-2 form-control is-invalid'
            this.showOutOfwharehousQuantity = true
          }
        } else {
          this.invoiceData.items[index].quantity = 1
          this.classQty = 'mb-2 form-control is-invalid'
          this.showOutOfwharehousQuantity = true
        }
      }
      this.sumForm()
    },
    getDocumentPrice() {
      let isPriceSale = false
      if (this.invoiceData.document_id !== 1 && this.invoiceData.document_id <= 7) {
        isPriceSale = true
      }
      return isPriceSale
    },
  },
  setup() {
    const itemFormBlankItem = {
      item: null,
      item_id: 0,
      price: 0,
      quantity: 1,
      measurement_unit: null,
      measurement_unit_id: 0,
      measurement_unit_option: [],
    }
    return {
      itemFormBlankItem,
    }
  },
}
</script>
